import * as React from 'react';
import { ConfigContext } from '../../config-provider';
import LocaleReceiver from '../../locale-provider/LocaleReceiver';
import defaultLocale from '../../locale/default';
import ConfirmDialog from '../ConfirmDialog';
const HookModal = ({ afterClose, config }, ref) => {
    const [open, setOpen] = React.useState(true);
    const [innerConfig, setInnerConfig] = React.useState(config);
    const { direction, getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('modal');
    const rootPrefixCls = getPrefixCls();
    const close = (...args) => {
        setOpen(false);
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (innerConfig.onCancel && triggerCancel) {
            innerConfig.onCancel(() => { }, ...args.slice(1));
        }
    };
    React.useImperativeHandle(ref, () => ({
        destroy: close,
        update: (newConfig) => {
            setInnerConfig(originConfig => (Object.assign(Object.assign({}, originConfig), newConfig)));
        },
    }));
    return (React.createElement(LocaleReceiver, { componentName: "Modal", defaultLocale: defaultLocale.Modal }, (modalLocale) => (React.createElement(ConfirmDialog, Object.assign({ prefixCls: prefixCls, rootPrefixCls: rootPrefixCls }, innerConfig, { close: close, open: open, afterClose: afterClose, okText: innerConfig.okText ||
            (innerConfig.okCancel ? modalLocale.okText : modalLocale.justOkText), direction: direction, cancelText: innerConfig.cancelText || modalLocale.cancelText })))));
};
export default React.forwardRef(HookModal);
