var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { render as reactRender, unmount as reactUnmount } from 'rc-util/lib/React/render';
import * as React from 'react';
import { globalConfig } from '../config-provider';
import warning from '../_util/warning';
import ConfirmDialog from './ConfirmDialog';
import destroyFns from './destroyFns';
import { getConfirmLocale } from './locale';
let defaultRootPrefixCls = '';
function getRootPrefixCls() {
    return defaultRootPrefixCls;
}
export default function confirm(config) {
    const container = document.createDocumentFragment();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    let currentConfig = Object.assign(Object.assign({}, config), { close, open: true });
    function destroy(...args) {
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(() => { }, ...args.slice(1));
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
        reactUnmount(container);
    }
    function render(_a) {
        var { okText, cancelText, prefixCls: customizePrefixCls, open, visible } = _a, props = __rest(_a, ["okText", "cancelText", "prefixCls", "open", "visible"]);
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            const runtimeLocale = getConfirmLocale();
            const { getPrefixCls, getIconPrefixCls } = globalConfig();
            // because Modal.config  set rootPrefixCls, which is different from other components
            const rootPrefixCls = getPrefixCls(undefined, getRootPrefixCls());
            const prefixCls = customizePrefixCls || `${rootPrefixCls}-modal`;
            const iconPrefixCls = getIconPrefixCls();
            reactRender(React.createElement(ConfirmDialog, Object.assign({}, props, { open: open !== null && open !== void 0 ? open : visible, prefixCls: prefixCls, rootPrefixCls: rootPrefixCls, iconPrefixCls: iconPrefixCls, okText: okText || (props.okCancel ? runtimeLocale.okText : runtimeLocale.justOkText), cancelText: cancelText || runtimeLocale.cancelText })), container);
        });
    }
    function close(...args) {
        currentConfig = Object.assign(Object.assign({}, currentConfig), { open: false, afterClose: () => {
                if (typeof config.afterClose === 'function') {
                    config.afterClose();
                }
                destroy.apply(this, args);
            } });
        render(currentConfig);
    }
    function update(configUpdate) {
        if (typeof configUpdate === 'function') {
            currentConfig = configUpdate(currentConfig);
        }
        else {
            currentConfig = Object.assign(Object.assign({}, currentConfig), configUpdate);
        }
        render(currentConfig);
    }
    render(currentConfig);
    destroyFns.push(close);
    return {
        destroy: close,
        update,
    };
}
export function withWarn(props) {
    return Object.assign(Object.assign({ icon: React.createElement(ExclamationCircleOutlined, null), okCancel: false }, props), { type: 'warning' });
}
export function withInfo(props) {
    return Object.assign(Object.assign({ icon: React.createElement(InfoCircleOutlined, null), okCancel: false }, props), { type: 'info' });
}
export function withSuccess(props) {
    return Object.assign(Object.assign({ icon: React.createElement(CheckCircleOutlined, null), okCancel: false }, props), { type: 'success' });
}
export function withError(props) {
    return Object.assign(Object.assign({ icon: React.createElement(CloseCircleOutlined, null), okCancel: false }, props), { type: 'error' });
}
export function withConfirm(props) {
    return Object.assign(Object.assign({ icon: React.createElement(ExclamationCircleOutlined, null), okCancel: true }, props), { type: 'confirm' });
}
export function modalGlobalConfig({ rootPrefixCls }) {
    warning(false, 'Modal', 'Modal.config is deprecated. Please use ConfigProvider.config instead.');
    defaultRootPrefixCls = rootPrefixCls;
}
